import { requireFun } from '@/utils'
//新增顶级图库类型
export const sizesPsdsFiled = [
  {
    label: '尺码',
    prop: 'sizeName',
    name: 'size_name',
    colNum: 24,
    slotName: 'size_nameSlot'
  },
  {
    label: 'psd文件',
    prop: 'files',
    name: 'files',
    colNum: 24,
    placeholder: '请输入psd文件',
    slotName: 'filesSlot'
  }
]
